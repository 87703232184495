import Firebase from "./firebase"
import Vue from 'vue'
import App from './App.vue'

import './registerServiceWorker'


// Webpack CSS import
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import router from "./router";
import VueMeta from 'vue-meta'
import VueShowdown from 'vue-showdown'

// the second parameter of Vue.use() is optional
Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: 'github',
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: true,
  },
})

 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// JS import
import VueOnsen from 'vue-onsenui'; // This imports 'onsenui', so no need to import it separately

import { store } from './store'
//import ons from "onsenui";


//export const EventBus = new Vue();



Vue.config.productionTip = false

Vue.use(VueOnsen); // VueOnsen set here as plugin to VUE. Done automatically if a call to window.Vue exists in the startup code.


router.beforeEach((to, from, next) => {

  if(!to.meta.protected) { //route is public, don't check for authentication
      next()
  } else {  //route is protected, if authenticated, proceed. Else, login
      Firebase.auth.onAuthStateChanged((user) => {
          console.log("main.js login/logout")
          if(user) {
              next()
          } else {
              router.push('/login')
          }
      })
  }
})
new Vue({
    
    store,
    router,
    beforeCreate(){
    //console.log = function(){}; // turn console.log off
        // Set iPhoneX flag based on URL
    //if (window.location.search.match(/iphonex/i)) {
   // if (this.$ons.platform.isIPhoneX())
   // {
     //   if(ons.platform.isIPhoneX()) {
  //      document.documentElement.setAttribute('onsflag-iphonex-portrait', '');
  //      document.documentElement.setAttribute('onsflag-iphonex-landscape', '');
  //    }
    },
    render: h => h(App),
    //components: { App }
}).$mount('#app')




