//for using $.each in looping
//TODO : demise jquery
import JQuery from "jquery";
let $ = JQuery;

//for API call
import axios from "axios";

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
export default {
    
    data(){
      return {
      xOwner:null,
      xProperty:null,
      xPID:''
      }
    },
    methods: {
      async GetParcelInfoByPID(iPID) {
        var aOwner = {};
        var aProperty = {};
        var aPID =iPID.substr(0, 3) + "-" + iPID.substr(3, 3) + "-" + iPID.substr(6);
        var info = "";
        var dbURL ="https://2caz1ubxn0.execute-api.us-west-2.amazonaws.com/prod/parcel/byID/" + aPID;
        let that=this
        //that.xOwner={};
        //that.xProperty={};
        await axios.get(proxyurl1 + dbURL).then(function(response) {
          console.log(response);
          if (response.data.statusCode == "200") {
            $.each(response.data.body.Info, function(key, value) {
              if (value == "null") {
                value = "";
              }
              aOwner[key] = value;
            });

            info +=
              "<div>" +
              aOwner.FirstName1 +
              " " +
              aOwner.LastName1 +
              "</div><div>" +
              aOwner.Company +
              "</div>";

            $.each(response.data.body, function(key, value) {
              if (key != "ParcelPolygon" && key != "Info") {
                if (key == "CivicAddress") {
                  info += "<div>" + value + "</div>";
                }
                if (value == "null") {
                  value = "";
                }
                aProperty[key] = value;
              }
            });

            aProperty["pid"] = aPID;
            that.xOwner=aOwner;
            that.xProperty=aProperty;
            that.xPID=aPID;
            console.log('xOwner',that.xOwner)
            console.log('xProperty',that.xProperty)

            }
            else {
              that.xProperty=null
              that.xOwner=null
              that.xPID=aPID
            }
            })
      }
    }
  }
