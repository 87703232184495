<template id="createpostPage">
  <v-ons-card>
    <div class="content">
      <!--<form  @submit.prevent> -->
      <p>
        <v-ons-input
          placeholder="Post title"
          v-model.trim="post.title"
        ></v-ons-input>
      </p>

      <p style="margin-top:5px;margin-bottom:5px;">
        <label class="left">
          <v-ons-checkbox
            :input-id="'chkbx_tagparcel'"
            :value="'Tag parcel'"
            v-model="post.tagParcel"
          ></v-ons-checkbox>
        </label>
        <label class="center">  Tag parcel</label>
      </p>
      <p>
        <textarea
          class="ptextarea"
          rows="10"
          cols="30"
          placeholder="Write a post... "
          v-model.trim="post.content"
        ></textarea>
      </p>
      <p>
        <v-ons-button
          modifier="large"
          @click="createPost"
          :disabled="post.content == ''"
          >post</v-ons-button
        >
      </p>
      <!-- </form> -->
    </div>
  </v-ons-card>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
// const fb = require('../firebaseConfig.js')
import fb from "../firebase";

export default {
  name: "createpost",
  props: ["ownerinfo", "propinfo","selectedCentre"],
  data() {
    return {
      post: {
        title: "",
        content: "",
        tagParcel: true,
      },
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  methods: {
    createPost() {
      console.log("ownerinfo", this.ownerinfo);
      console.log("propinfo", this.propinfo);
      console.log("content", this.post.content);
      console.log("uid", this.currentUser.uid);
      console.log("name", this.currentUser.displayName);
      console.log("users",this.currentUser)
      console.log("tagParcel", this.post.tagParcel);
      fb.postsCollection
        .add({
          createdOn: new Date(),
          deleted:false,
          title: this.post.title,
          content: this.post.content,
          userId: this.currentUser.uid,
          userName: this.currentUser.displayName,
          userPhotoURL: this.currentUser.photoURL,
          comments: 0,
          likes: 0,
          tagParcel: this.post.tagParcel,
          ownerinfo: this.post.tagParcel ? this.ownerinfo : null,
          propinfo: this.post.tagParcel ? this.propinfo : null,
          centre:this.post.tagParcel ? this.selectedCentre.flatCoordinates: null
        })
        .then((ref) => {
          this.post.content = "";
          this.post.title = "";
          this.post.tagParcel = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
<style scoped>
.ptextarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}
</style>
