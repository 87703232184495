import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";
import KML from "ol/format/KML";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import fb from "../firebase";
import { store } from "../store";
import MapboxVectorLayer from "ol/layer/MapboxVector";

const key =
  "pk.eyJ1IjoiYWxmcmVkLWN3LWNoZW4iLCJhIjoiY2w0azZ3MG1hMTk5djNkb2JxZzhscnVtaCJ9.LzDKJGXqD4lOlSERZPOqsw";
var style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.6)",
  }),
  stroke: new Stroke({
    color: "#319FD3",
    width: 1,
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "#000",
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3,
    }),
  }),
});

var indmap = function() {
  var indlayer = new VectorLayer({
    source: new VectorSource({
      url: "data/GV_Industrial.kml",
      format: new KML(),
    }),
  });
  indlayer.getSource().on("change", function(evt) {
    const source = evt.target;
    if (source.getState() === "ready") {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  return indlayer;
};
var mbmap = function() {
  var mblayer = null;
  mblayer = new MapboxVectorLayer({
    styleUrl: "mapbox://styles/mapbox/light-v10",
    accessToken: key,
    type: "base",
    baseLayer: true,
    zIndex: 0,
  });
  return mblayer;
};

var streetmap = function() {
  var mapbaselayer = null;
  mapbaselayer = new TileLayer({
    //source: new OSM(),

    source: new XYZ({
      url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    }),
    type: "base",
    baseLayer: true,
  });
  return mapbaselayer;
};
var googlemap = function() {
  var mapbaselayer = null;
  mapbaselayer = new TileLayer({
    source: new XYZ({
      attributions:
        '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
      //attributionsCollapsible: false,
      url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
      maxZoom: 18,
    }),
    type: "base",
    baseLayer: true,
  });
  return mapbaselayer;
};
var satellitemap = function() {
  var additonallayers = null;
  additonallayers = new TileLayer({
    title: "Satellite view",
    name: "GSatellite",
    zIndex: 3,
    visible: false,
    source: new XYZ({
      attributions: [
        "Powered by Esri",
        "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
      ],
      url:
        "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      maxZoom: 23,
    }),
  });
  return additonallayers;
};
var bcparcelmap = function() {
  var parcelLayer = new TileLayer({
    title: "Parcel lines",
    displayInLayerPopup: "0",
    opacity: 0.9,
    minZoom: 12,
    zIndex: 5,

    source: new TileWMS({
      url:
        "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
      crossOrigin: null,
      params: {
        LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
        STYLES: ["5898"],
      },
    }),
  });
  return parcelLayer;
};
var bcparcelmap_data = function() {
  var parcelLayer = new TileLayer({
    //title: 'Parcel lines',
    displayInLayerPopup: "0",
    opacity: 0.7,
    minZoom: 12,
    visible: false,

    source: new TileWMS({
      url:
        "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
      crossOrigin: null,
      params: {
        LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
        STYLES: ["5903"],
      },
    }),
  });
  return parcelLayer;
};
var bookmarksmap = function() {
  var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer = new VectorLayer({
    title: "Bookmarks",
    name: "Bookmarks",
    source: new VectorSource(),
    style: style,
    visible: false,
    zIndex: 4,
  });

  return bookmarkLayer;
};
var mapconfig = {
  x: -123.1175904,
  y: 49.28764,
  zoom: 17,
  streetmap: streetmap(),
  //googlemap: googlemap(),
  satellitemap: satellitemap(),
  bcparcelmap: bcparcelmap(),
  bookmarksmap: bookmarksmap(),
  indmap: indmap(),
  mbmap: mbmap(),
  bcparceldata: bcparcelmap_data(),
};
export default mapconfig;
