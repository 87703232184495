<template id="ownerinfo">
  <v-ons-page id="ownerinfoPage">
    <v-ons-card>
      <div v-if="hasData">
        <div class="card__content">
          <div
            class="profile-card"
            style="background-color: black; color: white"
          >
            <div
              class="profile-name"
              style="background-color: black; color: white"
            >
              <span>{{ FullName1 }}</span>
            </div>
            <div class="profile-name" v-if="FullName2 != ''">
              <span>{{ FullName2 }}</span>
            </div>
            <div class="profile-name" v-if="Company != ''">
              <span>{{ Company }}</span>
            </div>
            <div
              v-if="Address != ''"
              style="
                text-align: left;
                margin: 10px 0 0 15px;
                font-size: 14px;
                color: white;
              "
            >
              {{ Address }}
            </div>
            <v-ons-list style="background-color: black; color: white">
              <v-ons-list-item
                style="
                  background-color: black;
                  color: white;
                  font-style: italic;
                "
              >
                <div>
                  <div
                    v-if="OfficeTel != ''"
                    style="
                      text-align: left;
                      margin: 10px 0 0 10px;
                      font-size: 14px;
                      color: white;
                      font-style: normal;
                    "
                  >
                    <v-ons-icon
                      icon="md-phone"
                      style="color: blue"
                    ></v-ons-icon>
                    Office: {{ OfficeTel }}
                  </div>
                  <div
                    v-if="MobileTel != ''"
                    style="
                      text-align: left;
                      margin: 10px 0 0 10px;
                      font-size: 14px;
                      color: white;
                      font-style: normal;
                    "
                  >
                    <i class="zmdi zmdi-phone" style="color: blue"></i>
                    Mobile: {{ MobileTel }}
                  </div>
                  <div
                    v-if="HomeTel != ''"
                    style="
                      text-align: left;
                      margin: 10px 0 0 10px;
                      font-size: 14px;
                      color: white;
                      font-style: normal;
                    "
                  >
                    <i class="zmdi zmdi-phone" style="color: blue"></i>
                    Home: {{ HomeTel }}
                  </div>
                  <div
                    v-if="Email1 != ''"
                    style="
                      text-align: left;
                      margin: 10px 0 0 10px;
                      font-size: 14px;
                      color: white;
                      font-style: normal;
                    "
                  >
                    <i class="zmdi zmdi-email" style="color: blue"></i>
                    Email: {{ Email1 }}
                  </div>
                  <div
                    v-if="Email2 != ''"
                    style="
                      text-align: left;
                      margin: 10px 0 0 10px;
                      font-size: 14px;
                      color: white;
                      font-style: normal;
                    "
                  >
                    <i class="zmdi zmdi-email" style="color: blue"></i>
                    Email: {{ Email2 }}
                  </div>
                </div>
              </v-ons-list-item>
            </v-ons-list>
          </div>
          <div>
            {{ this.db }}
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; font-size: 20px; margin-top: 20px">
        Owner information is coming...
      </div>
    </v-ons-card>
    <v-ons-card v-show="hasData">
      <div class="card__content">
        <v-ons-list>
          <v-ons-list-item modifier="nodivider">
            <v-ons-row style="height: 100px" vertical-align="center">
              <v-ons-col class="info-col" vertical-align="center" width="50%">
                <div>
                  <img
                    src="../assets/img/property1.png"
                    alt="No Of Properties"
                    style="width: 30px; height: 30px"
                  />
                </div>
                <div class="info-num">{{ NoOfProp }}</div>
                <div class="info-unit">Properties</div>
              </v-ons-col>
              <v-ons-col class="info-col" vertical-align="center" width="50%">
                <div>
                  <img
                    src="../assets/img/portfolio1.png"
                    alt="Total Portfolio"
                    style="width: 30px; height: 30px"
                  />
                </div>
                <div class="info-num currency">{{ TotalPortfolio }}</div>
                <div class="info-unit">Portfolio Value</div>
              </v-ons-col>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item modifier="nodivider">
            <Table v-bind:aportfolio="Object.values(this.portfolio)"></Table>
          </v-ons-list-item>
        </v-ons-list>
      </div>
    </v-ons-card>
    <v-ons-card v-show="hasData">
      <div v-if="hasData">
        <div class="card__content">
          <div style="padding: 16px">
            <div v-show="ContactID">
              <v-ons-row>
                <v-ons-col width="30%">Contact ID:</v-ons-col>
                <v-ons-col>{{ ContactID }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="Relationship">
              <v-ons-row>
                <v-ons-col width="30%">Relationship: </v-ons-col>
                <v-ons-col>{{ Relationship }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="MarketPosition">
              <v-ons-row>
                <v-ons-col width="30%">Market Position: </v-ons-col>
                <v-ons-col>{{ MarketPosition }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="InvestmentRange">
              <v-ons-row>
                <v-ons-col width="30%">Investment Range: </v-ons-col>
                <v-ons-col>{{ InvestmentRange }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="LastResult">
              <v-ons-row>
                <v-ons-col width="30%">Last Result: </v-ons-col>
                <v-ons-col> {{ LastResult }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="NextAction">
              <v-ons-row>
                <v-ons-col width="30%">Next Action: </v-ons-col>
                <v-ons-col> {{ NextAction }}</v-ons-col>
              </v-ons-row>
            </div>
          </div>
        </div>
      </div>
    </v-ons-card>
    <v-ons-card>
      <div v-if="prop_hasData">
        <div class="card__content">
          <v-ons-card style="background-color: black; color: white">
            <v-ons-list style="background-color: black; color: white">
              <div class="left">
                <img
                  style="display: in-block"
                  :src="AddressURL"
                  alt="Street View is not available."
                />
              </div>
              <div
                class="left"
                @click="actionSheetVisible = true"
                style="background-color: black; color: white"
              >
                <div>{{ prop_Address }}</div>
                <div>{{ prop_Zoning }}</div>
                <div>{{ prop_LegalOwner }}</div>
              </div>
            </v-ons-list>
          </v-ons-card>
        </div>
      </div>
      <div v-else style="text-align: center; font-size: 20px; margin-top: 20px">
        Property information is coming...
      </div>
    </v-ons-card>
    <v-ons-card v-show="prop_hasData">
      <div class="card__content">
        <v-ons-list-item modifier="nodivider">
          <div>
            <v-ons-row>
              <v-ons-col>Lot Size: </v-ons-col>
              <v-ons-col>{{ prop_LotSize }}</v-ons-col>
            </v-ons-row>
            <div v-show="prop_BuildingStoreys != ''">
              <v-ons-row>
                <v-ons-col>Building Storeys: </v-ons-col>
                <v-ons-col>{{ prop_BuildingStoreys }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="prop_NoOfUnits != ''">
              <v-ons-row>
                <v-ons-col>No Of Units: </v-ons-col>
                <v-ons-col>{{ prop_NoOfUnits }}</v-ons-col>
              </v-ons-row>
            </div>
            <v-ons-row>
              <v-ons-col>Gross Tax: </v-ons-col>
              <v-ons-col>{{ prop_GrossTax }}</v-ons-col>
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Assessment (year): </v-ons-col>
              <v-ons-col
                >{{ prop_AssessmentTotalValue }} ({{
                  prop_AssessmentYear
                }})</v-ons-col
              >
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Land:</v-ons-col>
              <v-ons-col>{{ prop_AssessmentLand }}</v-ons-col>
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Buildings:</v-ons-col>
              <v-ons-col>{{ prop_AssessmentBuilding }}</v-ons-col>
            </v-ons-row>
          </div>
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="prop_hasData">
      <div class="card__content">
        <v-ons-list-item modifier="nodivider" tappable @click="go2propPosts">
          Tagged posts
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="prop_hasHistory">
      <div class="card__content">
        <v-ons-list-item
          >history...
          <div></div>
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="prop_hasNotes">
      <div class="card__content">
        <v-ons-list-item
          >Notes...
          <div></div>
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="prop_hasData">
      <v-ons-list-item modifier="nodivider" @click="showTitleSearch">
        <v-ons-icon
          icon="md-file-text"
          size="30px"
          style="margin-right: 10px"
        ></v-ons-icon>
        Title search
      </v-ons-list-item>
      <v-ons-list-item modifier="nodivider" @click="showCompanySearch">
        <v-ons-icon
          icon="md-file-text"
          size="30px"
          style="margin-right: 10px"
        ></v-ons-icon>
        Company search
      </v-ons-list-item>
    </v-ons-card>
    <v-ons-dialog cancelable :visible.sync="showDialog">
      <PDFJSViewer :path="`${path}`" :name="`${name}`" />
    </v-ons-dialog>
    <v-ons-fab
      v-show="hasData && !ReadOnlyDB"
      position="top right"
      :disabled="false"
      @click.prevent="
        router.push({ name: 'editowner', params: { owner: owner } })
      "
    >
      <v-ons-icon icon="ion-ios-create"></v-ons-icon>
    </v-ons-fab>
  </v-ons-page>
</template>
<script>
// Import the EventBus we just created.
import EventBus from "../config/event-bus";
import router from "../router";
//import BasicTableApp from "../components/portfoliotv";

//for API call
import axios from "axios"
import fb from "../firebase"
import PDFJSViewer from "../components/PDFJSViewer"
import Table from "../components/table.vue"

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

export default {
  data: function () {
    return {
      owner: null,
      FullName1: "",
      FullName2: "",
      Company: "",
      Address: "",
      HomeTel: "",
      OfficeTel: "",
      MobileTel: "",
      Email1: "",
      Email2: "",
      NoOfProp: 0,
      TotalPortfolio: 0,
      LastResult: "",
      NextAction: "",
      ContactID: "",
      Relationship: "",
      InvestmentRange: "",
      MarketPosition: "",
      Type: "",
      hasData: false,
      actionSheetVisible: false,
      router: router,
      ReadOnlyDB: false,

      prop_PID: "",
      prop_Address: "",
      prop_Zoning: "",
      prop_LotSize: 0,
      prop_BuildingStoreys: 0,
      prop_NoOfUnits: 0,
      prop_GrossTax: 0,
      prop_AssessmentTotalValue: 0,
      prop_AssessmentYear: "",
      prop_AssessmentLand: 0,
      prop_AssessmentBuilding: 0,
      prop_LegalOwner: "",
      prop_hasData: false,
      prop_hasNotes: false,
      prop_hasHistory: false,
      prop_hasPosts: true,
      AddressURL: "",
      dialogVisible: false,
      actionSheetVisible: false,
      showStreetView: false,
      lonlat: null,
      showDialog: false,
      // for using pdf.js pre-build web viewer
      name: "Test_PDF.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      db: null,
      id_contacts: null,
      portfolio: [],
      columns: [
        {
          label: "PID",
          field: "pid",
        },
        {
          label: "Property Address",
          field: "LocationOriginal",
        },
        {
          label: "Legal Owner",
          field: "LO",
        },
      ],
      //
    };
  },
  mounted() {
    EventBus.$on("get_db-contactid", (data) => {
      console.log("Get db contactid event in ownerinfo: ", data);
      //this.getPortfolioRows(data);
    }),
      EventBus.$on("get_owner", (data) => {
        console.log("Get owner event in ownerinfo: ");

        if (data !== null) {
          this.owner = data;
          this.FullName1 = (data.FirstName1 + " " + data.LastName1).trim();
          this.FullName2 = (data.FirstName2 + " " + data.LastName2).trim();
          this.Company = data.Company.trim();
          this.Address = data.Address.trim();
          this.HomeTel = data.HomeTel.trim();
          this.OfficeTel = data.ContactNo.trim();
          this.MobileTel = data.MobileTel.trim();
          this.Email1 = data.Email_key3.trim();
          this.Email2 = data.Email.trim();
          this.NoOfProp = data.NoOfProperties.trim();
          this.TotalPortfolio = data.TotalPortfolio.trim();
          this.LastResult = data.LastResult.trim();
          this.NextAction = data.NextAction.trim();
          this.ContactID = data.ContactID.trim();
          this.Relationship = data.Relationship.trim();
          this.InvestmentRange = data.InvestmentRange.trim();
          this.MarketPosition = data.MarketPosition.trim();
          this.Type = data.Type.trim();
          this.hasData = true;
          this.db = data.db;
          this.id_contacts = data.id_contacts;
          this.getPortfolioRows("");
        } else this.hasData = false;
      }),
      EventBus.$on("get_coordinate", (data) => {
        console.log("Get lonlat event in propertyinfo");
        this.lonlat = data;
      }),
      EventBus.$on("get_property", (data) => {
        console.log("Get property event in propertyinfo: ");
        if (data !== null) {
          this.prop_PID = data.Pid;
          this.prop_Address = data.CivicAddress;
          this.prop_Zoning = data.Zoning;
          this.prop_LotSize = data.LotSize;
          this.prop_BuildingStoreys = data.BuildingStoreys;
          this.prop_NoOfUnits = data.NoOfUnit;
          this.prop_GrossTax = data.GrossTax.replace(/.(?=(?:.{3})+$)/g, "$&,");
          this.prop_AssessmentTotalValue = data.AssessmentValue.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.prop_AssessmentYear = data.AssessmentYear;
          this.prop_AssessmentLand = data.CurrAssessLand.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.prop_AssessmentBuilding = data.CurrAssessBuilding.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.prop_LegalOwner = data.LO;
          this.prop_hasData = true;
          this.AddressURL =
            "https://maps.googleapis.com/maps/api/streetview?size=600x315&source=outdoor&location=" +
            data.Address +
            "&key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs";
        } else this.prop_hasData = false;
      });
  }, // end created
  components: {
    PDFJSViewer,
    Table,
  },
  methods: {
    showCompanySearch() {
      const pdf_name_prefix = "corporatesearch/";
      var pdf_name = pdf_name_prefix + this.prop_LegalOwner + ".pdf";
      var starsRef = fb.storage2.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function (url) {
          console.log("pdf URL: " + url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Company Search error:", error);
          this.$ons.notification.toast("No company search document found!", {
            timeout: 2000,
          });
        });
    },
    showTitleSearch() {
      const pdf_name_prefix = "titlesearch/";
      var pdf_name = pdf_name_prefix + this.prop_PID + ".pdf";
      var starsRef = fb.storage2.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function (url) {
          console.log("pdf URL: " + url);

          //EventBus.$emit("get_url", url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Title Search error:", error);
          this.$ons.notification.toast("No title search document found!", {
            timeout: 2000,
          });
        });
    },
    go2propPosts() {
      let senddata = { pid: this.PID, address: this.Address, hash: this.hash };
      EventBus.$emit("propPosts", senddata);
      document.querySelector("ons-tabbar").setActiveTab(4);
    },
    async getPortfolioRows(db_contactid) {
      //var acontactid = db_contactid.split("-")[1];
      //var adb = db_contactid.split("-")[0];
      var acontactid = this.id_contacts;
      var adb = this.db;
      // acontactid=5313
      // adb='apt'
      var dbURL =
        "https://us-central1-terratonepull-c669d.cloudfunctions.net/PortfolioTreeView?database=" +
        adb +
        "&contactID=" +
        acontactid;
      let that = this;
      await axios.get(proxyurl1 + dbURL).then(function (response) {
        // console.log("portfolio:", response);
        //EventBus.$emit("portfolio", response.data[0]);
        that.portfolio = response.data[0];
        console.log("that.portfolio:", that.portfolio);
      });
    },
    goToSearch() {
      router.push({
        path: "/search",
        name: "search",
        params: { strSearchFromOwnerInfo: this.Company },
      });
    },
    goToSearch2FN1() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.FullName1);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2FN2() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.FullName2);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2CY() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.Company);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
  },
  computed: {
    hasPropInfo() {
      return this.prop_hasData;
    },
    hasOwnerInfo() {
      //return (this.hasData)
      return true;
    },
  },
};
</script>
<style scoped>
.profile-card {
  width: 100%;
  text-align: left;
  color: black;
  padding: 10px 0;
  line-height: 1.4;
  background-color: white;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 0px;
}

.profile-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: black;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0px 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0px 0px;
}

.profile-name {
  margin: 5px 5px 5px 15px;
  font-weight: 200;
  font-size: 18px;
}

.profile-id {
  margin: 0 0 5px 0;
  font-size: 14px;
  opacity: 0.6;
}

.profile-desc {
  font-size: 15px;
  opacity: 0.6;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.profile-pic {
  width: 50px;
  background-color: #fff;
  margin: 20px auto 10px;
  border: 1px solid #999;
  border-radius: 4px;
}

.profile-pic > img {
  display: block;
  max-width: 100%;
}

.info-col {
  height: 80px;
  line-height: 1;
  padding: 5px 0 5px 4px;
}

.info-num {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}

.info-unit {
  margin-top: 6px;
  font-size: 12px;
  opacity: 0.4;
}

.currency:before {
  content: "$";
}
/* .currency:after{ content: '.00'; } */

.search-bar {
  width: 100%;
  /*position: fixed;*/
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* Style buttons */
.btn {
  background-color: transparent; /* Blue background */
  border: none; /* Remove borders */
  color: blue; /* White text */
  padding: 5px 5px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over  */
.btn:hover {
  color: red; /* White text */
}

img {
  border: 1px solid #021a40;
}

table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
}
table.vgt-table {
  font-size: 10px;
  font-weight: bold;
}
</style>

